import React from 'react';
import { Alert, Col, NavbarBrand, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FiLogIn } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import FeedbackModal from '../../dashboard-ui/containers/feedbackModal';
import JumpToShot from '../../dashboard-ui/components/jumpToShot';

const LoginToAccess = ({ t }) => {

  return (
    <>
      <Row className="not-banner align-items-center">
        <Col>
          <Row className="align-items-center">
            <Col className="mx-md-4 brand-div">
              <NavbarBrand tag={Link} to="/">{t('name')}</NavbarBrand>
            </Col>
            <Col>
              <FeedbackModal/>
            </Col>
            <div className="mr-md-4 hide-me jump-to-shot">
              <JumpToShot/>
            </div>
          </Row>
        </Col>
      </Row>
      <Alert color="warning" className="mt-3 mx-md-2">
        <FiLogIn className="mr-3" />
        {t('common:login_to_access')}
      </Alert>
    </>
  );
};

export default withTranslation()(LoginToAccess);

LoginToAccess.propTypes = {
  t: PropTypes.func, //HOC
};
