import React, { useState, useEffect, useCallback } from 'react';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Button, FormGroup, Label, InputGroup, Input, Form } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import NumberFormControl from '../components/numberFormControl';
import TextFormControl from '../components/textFormControl';
import { withGetData } from '../../main/hoc/withGetData';
import * as notify from '../../main/utils/notify';
import VariablesForm from './variablesForm';
import useForm from '../hooks/useForm';
import Select from 'react-select';
import ConfirmationModal from '../components/confirmationModal';
import {
  getInputType,
  getIdsFieldsFromParams,
  getFormControls,
  getFiltersFromParams,
  getUrlParamsFromFormControls,
  getMachinesFromParams
} from '../utils';
import { variablesTypes } from '../constants';
import Loading from '../../main/components/loading';
import { ErrorBoundary } from '../../main/hoc/errorboundary';

const FiltersForm = ({ variables, machines, onOpenHistogramModal, formControls, setFormControls, initialFiltersValues, defaultFormControls }) => {
  const [ allVariables, setAllVariables ] = useState([]);
  const [ machinesOptions, setMachinesOptions ] = useState([]);
  const [ selectedMachines, setSelectedMachines ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ confirmationModal, setConfirmationModal ] = useState({
    isOpen: false,
    onConfirm: () => { }
  });
  const { fields, handleFieldChange, clearFields, setFields, removeFields } = useForm({
    creation_date_min: '',
    creation_date_max: ''
  });
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (variables.data && allVariables.length === 0) {
      const options = variables.data.map((option) => option);
      setAllVariables(options);
    }
  }, [ variables, allVariables ]);

  useEffect(() => {
    if (machines.data && machinesOptions.length === 0) {
      const options = machines.data.machines.map(name => ({
        value: name,
        label: name
      }));
      if (options.length) setMachinesOptions(options);
    }
  }, [ machines, machinesOptions ]);

  useEffect(() => {
    setFields(initialFiltersValues);
  }, [ initialFiltersValues, setFields ]);

  useEffect(() => {
    const search = location.search;

    if (search) {
      setIsLoading(true);
      if (allVariables.length) {
        const params = new URLSearchParams(search);
        
        if (params.has('idsFields')) {
          const idsFields = getIdsFieldsFromParams(params);
          const newFormControls = getFormControls(idsFields, variablesTypes, allVariables);
          setFormControls(newFormControls);
        }

        setSelectedMachines(getMachinesFromParams(params));
        setFields(getFiltersFromParams(params));
        setIsLoading(false);
      }
    }
  }, [ location, allVariables, setFields, setFormControls ]);

  const onAddFormControl = useCallback(async (selectedOption) => {
    const variable = allVariables.find(({ idsField }) => idsField === selectedOption);

    if (!variable) {
      notify.error(
        t('filters_form.notifications.ids_field_does_not_exist.title'),
        t('filters_form.notifications.ids_field_does_not_exist.body')
      );
    }
    else {
      const formControlExists = formControls.find(({ idsField }) => idsField === variable.idsField);

      if (formControlExists) {
        notify.warning(
          t('filters_form.notifications.form_field_exists.title'),
          t('filters_form.notifications.form_field_exists.body')
        );
      }
      else {
        const variableType = variablesTypes.find(({ id }) => id === variable.variableTypeId);
        const updatedFormControls = [ 
          ...formControls, 
          { type: getInputType(variableType.value), ...variable } 
        ];

        setFormControls(updatedFormControls);
        notify.success(
          t('filters_form.notifications.form_field_created.title'),
          t('filters_form.notifications.form_field_created.body')
        );
      }
    }
  }, [ formControls, allVariables, t, setFormControls ]);

  const onDeleteFormControl = (idsField) => {
    const updatedFormControls = formControls.filter(item => item.idsField !== idsField);
    setFormControls(updatedFormControls);
    removeFields(idsField);
    setConfirmationModal({
      isOpen: false,
      onConfirm: () => { }
    });
  };

  const handleReset = () => {
    setSelectedMachines([]);
    clearFields();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formControls.length !== 0) {
      const params = new URLSearchParams();

      for (let field in fields) {
        params.append(field.replace(/\//g, '.'), fields[field]);
      }

      if (selectedMachines) {
        const machinesList = selectedMachines.map(({ value }) => value).join('.');
        params.append('machines', machinesList);
      }

      params.append('idsFields', getUrlParamsFromFormControls(formControls));
      history.push(`/shots?${params.toString()}`);
    }
    else {
      notify.warning(
        t('filters_form.notifications.empty_form.title'),
        t('filters_form.notifications.empty_form.body')
      );
    }
  };

  const handleResetToDefaults = () => {
    setFormControls(defaultFormControls);
    clearFields();
    const params = new URLSearchParams();
    params.append('idsFields', getUrlParamsFromFormControls(defaultFormControls));
    history.push(`/shots?${params.toString()}`);
  };

  return (
    <>
      <ConfirmationModal
        confirmationModal={confirmationModal}
        setConfirmationModal={setConfirmationModal}
      />
      <Col>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>{t('date_label')}</Label>
            <InputGroup>
              <Input
                type='date'
                name='creation_date_min'
                value={fields.creation_date_min}
                onChange={handleFieldChange}
              />
              <Input
                type='date'
                name='creation_date_max'
                value={fields.creation_date_max}
                onChange={handleFieldChange}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label>{t('machine_label')}</Label>
            <Select
              value={selectedMachines}
              onChange={(options) => setSelectedMachines(options)}
              options={machinesOptions}
              isMulti={true}
              placeholder='Any'
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#3071b3'
                }
              })}
              styles={{
                container: (provided) => ({
                  ...provided,
                  zIndex: '1000'
                })
              }}
            />
          </FormGroup>
          {isLoading ? (
            <Loading className='text-center my-3' />
          ) : (
            <TransitionGroup>
              {formControls.map(item => (
                <CSSTransition
                  key={item.variableId}
                  timeout={400}
                  classNames='list-animation-item'
                >
                  {item.type === 'text' ? (
                    <TextFormControl
                      label={item.variableDefinition}
                      name={item.idsField}
                      value={fields[item.idsField] || ''}
                      onChange={handleFieldChange}
                      onDelete={() => {
                        setConfirmationModal({
                          isOpen: true,
                          onConfirm: () => onDeleteFormControl(item.idsField)
                        });
                      }}
                    />
                  ) : (
                    <NumberFormControl
                      label={item.variableDefinition}
                      idsField={item.idsField}
                      minValue={fields[`${item.idsField}_min`] || ''}
                      maxValue={fields[`${item.idsField}_max`] || ''}
                      minValueName={`${item.idsField}_min`}
                      maxValueName={`${item.idsField}_max`}
                      variableTypeId={item.variableTypeId}
                      onChange={handleFieldChange}
                      onDelete={() => {
                        setConfirmationModal({
                          isOpen: true,
                          onConfirm: () => onDeleteFormControl(item.idsField)
                        });
                      }}
                      onOpenHistogramModal={onOpenHistogramModal}
                    />
                  )}
                </CSSTransition>
              ))}
            </TransitionGroup>
          )}
          <Row className='d-flex my-2'>
            <Col>
              <Button
                type='submit'
                block
                color='primary'
              >
                {t('filters_form_search_button')}
              </Button>
            </Col>
            <Col>
              <Button
                type='button'
                block
                color='primary'
                onClick={handleReset}
              >
                {t('filters_form_reset_button')}
              </Button>
            </Col>
          </Row>
        </Form>
        <VariablesForm variables={allVariables} onSubmit={onAddFormControl} />
        <Button onClick={handleResetToDefaults} block>{t('reset_to_defaults_button')}</Button>
      </Col>
    </>
  );
};

FiltersForm.propTypes = {
  variables: PropTypes.object.isRequired,
  defaultFormControls: PropTypes.array.isRequired,
  machines: PropTypes.object.isRequired, // HOC
  onOpenHistogramModal: PropTypes.func.isRequired,
  initialFiltersValues: PropTypes.object.isRequired,
  formControls: PropTypes.array.isRequired,
  setFormControls: PropTypes.func.isRequired,
};

export default compose(
  withGetData({
    machines: '/all-machines'
  }),
  ErrorBoundary((props) => props.t('components.filters_form'))
)(FiltersForm);