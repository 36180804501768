import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import Loading from '../../main/components/loading';

const AnnotationsList = ({ error, annotations, isLoading, onDelete }) => {
  const { t } = useTranslation();

  if (error) {
    return <p className='text-center'>{error}</p>;
  }
  else if (!annotations || isLoading) {
    return <Loading className='text-center' />;
  }
  else if (annotations.length === 0) {
    return <p className='text-center'>{t('annotations_modal.annotations_not_found')}.</p>;
  }
  else {
    return (
      <ListGroup>
        <TransitionGroup>
          {annotations.map(({ id, text }) => (
            <CSSTransition
              key={id}
              timeout={500}
              classNames='list-animation-item'
            >
              <ListGroupItem>
                <div className='d-flex justify-content-between align-items-center'>
                  <span>{text}</span>
                  <button
                    disabled={isLoading}
                    className='btn-bg-image btn-remove'
                    title='Delete annotation'
                    data-testid={`delete-btn-${id}`}
                    onClick={() => onDelete(id)}
                  >
                  </button>
                </div>
              </ListGroupItem>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </ListGroup>
    );
  }
};

AnnotationsList.propTypes = {
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  annotations: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default AnnotationsList;
