import { useState, useCallback } from 'react';

function useForm(initialState) {
  const [ values, setValues ] = useState(initialState);

  const handleFieldChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const clearFields = () => {
    const emptyFields = {};
    for (let field in values) {
      emptyFields[field] = '';
    }
    setValues(emptyFields);
  };

  const setFields = useCallback((newFields) => {
    setValues(prev => ({
      ...prev,
      ...newFields
    }));
  }, []);

  const removeFields = (field) => {
    setValues((prev) => {
      return Object.keys(prev).reduce((result, key) => {
        if (!key.includes(field)) {
          return { ...result, [key]: prev[key] };
        }
        return result;
      }, {});
    });
  };

  return { 
    fields: values, 
    handleFieldChange, 
    clearFields, 
    setFields,
    removeFields 
  };
}

export default useForm;