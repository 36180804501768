import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const footer = ({ t }) => (
  <footer>
    <Container fluid>
      <Row>
        <Col>
          <p>{t('footer_disclaimer')}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{t('footer_version')}</p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default withTranslation()(footer);

footer.propTypes = {
  t: PropTypes.func, //HOC
};
