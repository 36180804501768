import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceArea } from 'recharts';

import { getValuesFromLabel } from '../utils';

const Histogram = ({ data, idsField, width, height, onZoom }) => {
  const [ x1, setX1 ] = useState('');
  const [ x2, setX2 ] = useState('');
  const [ minValue, setMinValue ] = useState('');
  const [ maxValue, setMaxValue ] = useState('');

  const handleMouseDown = (e) => {
    if (e && e.activeLabel) {
      const activeLabel = e.activeLabel;
      setX1(activeLabel);
      const { min, max } = getValuesFromLabel(activeLabel);
      setMinValue(Number(min));
      setMaxValue(Number(max));
    }
  };

  const handleMouseMove = (e) => {        
    if (x1) {
      const activeLabel = e.activeLabel;

      if (activeLabel) {
        setX2(activeLabel);
        const { min, max } = getValuesFromLabel(activeLabel);
        
        if (minValue > min) {
          setMinValue(Number(min));
        }
      
        if (maxValue < max) {
          setMaxValue(Number(max));
        }
      }
      else {
        setX1('');
        setX2('');
      }
    }
  };

  const handleMouseUp = () => {
    if (x1 && x2) {
      onZoom(minValue, maxValue);
      setX1('');
      setX2('');
    }
  };
  
  return (
    <div className='unselectable'>
      <BarChart 
        width={width} 
        height={height} 
        data={data} 
        barCategoryGap={0}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp} 
        className='mx-auto'
      >
        <XAxis 
          dataKey='name' 
          tick={{ fontSize: 10 }}
          padding={{ left: 20, right: 20, top: 5, bottom: 5 }}  
        />
        <YAxis 
          allowDataOverflow 
          tickCount={10} 
          allowDecimals={false} 
        />
        <Tooltip />
        <Legend />
        <CartesianGrid strokeDasharray='3 3' />
        <Bar dataKey={idsField} fill='#3071b3' />
        {x1 && x2 ? (
          <ReferenceArea x1={x1} x2={x2} strokeOpacity={0.3} />
        ) : null} 
      </BarChart>
    </div>
  );
};

Histogram.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number
  })).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  idsField: PropTypes.string.isRequired,
  onZoom: PropTypes.func.isRequired
};

Histogram.defaultProps = {
  width: 700,
  height: 500
};

export default Histogram;
