import { getExperimentInfoFromUri } from '../utils';

const namespace = '@DASHBOARD_UI/ENTRY_DATA/';

export const GET_ENTRY_DATA_REQUEST = namespace + 'GET_ENTRY_DATA_REQUEST';
export const GET_ENTRY_DATA_SUCCESS = namespace + 'GET_ENTRY_DATA_SUCCESS';
export const GET_ENTRY_DATA_ERROR = namespace + 'GET_ENTRY_DATA_ERROR';
export const CLEAR_ENTRY_DATA_ERROR = namespace + 'CLEAR_ENTRY_DATA_ERROR';
export const GET_EXPERIMENT_INFO_REQUEST = namespace + 'GET_EXPERIMENT_INFO_REQUEST';
export const GET_EXPERIMENT_INFO_SUCCESS = namespace + 'GET_EXPERIMENT_INFO_SUCCESS';
export const GET_EXPERIMENT_INFO_ERROR = namespace + 'GET_EXPERIMENT_INFO_ERROR';
export const CLEAR_EXPERIMENT_INFO_ERROR = namespace + 'CLEAR_EXPERIMENT_INFO_ERROR';
export const SET_PAGINATION_INFO = namespace + 'SET_PAGINATION_INFO';
export const SET_SEARCH = namespace + 'SET_SEARCH';
export const GET_EXPERIMENT_IDS_FIELDS_REQUEST = namespace + 'GET_EXPERIMENT_IDS_FIELDS_REQUEST';
export const GET_EXPERIMENT_IDS_FIELDS_SUCCESS = namespace + 'GET_EXPERIMENT_IDS_FIELDS_SUCCESS';
export const GET_EXPERIMENT_IDS_FIELDS_ERROR = namespace + 'GET_EXPERIMENT_IDS_FIELDS_ERROR';
export const GET_FAIR_DATA_REQUEST = namespace + 'GET_FAIR_DATA_REQUEST';
export const GET_FAIR_DATA_SUCCESS = namespace + 'GET_FAIR_DATA_SUCCESS';
export const GET_FAIR_DATA_ERROR = namespace + 'GET_FAIR_DATA_ERROR';

export const getEntryData = ({
  sendRequest,
  experimentId,
  page,
  filters = [],
}) => async (dispatch) => {
  try {
    dispatch({ type: GET_ENTRY_DATA_REQUEST });

    const { data } = await sendRequest('post', '/entrydata/get-by-uri', {
      experiment_id: experimentId,
      page,
      page_size: 10,
      filters
    });

    dispatch({
      type: GET_ENTRY_DATA_SUCCESS,
      payload: data.data
    });
    dispatch({
      type: SET_PAGINATION_INFO,
      payload: {
        currentPage: data.current_page,
        elementsOnPage: data.elements_on_page,
        pageSize: data.page_size,
        totalElements: data.total_elements,
        pages: data.pages
      }
    });
  }
  catch (err) {
    let errorMessage = err.message;
    if (err.response) {
      if (err.response.status === 404) {
        errorMessage = '404';
      }
      else {
        errorMessage = err.response.data.message;
      }
    }

    dispatch({ type: GET_ENTRY_DATA_ERROR, payload: errorMessage });
  }
};

export const getExperimentInfo = ({ sendRequest, experimentId }) => async (dispatch) => {
  try {
    dispatch({ type: GET_EXPERIMENT_INFO_REQUEST });

    const { data } = await sendRequest('get', `experiment/${experimentId}`);
    const experimentInfo = getExperimentInfoFromUri(data.uri);

    dispatch({
      type: GET_EXPERIMENT_INFO_SUCCESS,
      payload: {
        experimentInfo,
        experimentId,
        source: data.uri 
      }
    });
  }
  catch (err) {
    const error = err.response ? err.response.data.message : err.message;
    dispatch({ type: GET_EXPERIMENT_INFO_ERROR, payload: error });
  }
};

export const getFairData = ({ sendRequest, experimentId }) => async (dispatch) => {
  try {
    dispatch({ type: GET_FAIR_DATA_REQUEST });
    const { data } = await sendRequest('post', '/entrydata/get-by-uri', {
      experiment_id: experimentId,
      filters: [],
      page: 1,
      // very big number to get all data in single request
      page_size: 100000000,
      ids_name: 'dataset_fair'
    });
    dispatch({ type: GET_FAIR_DATA_SUCCESS, payload: data.data });
  }
  catch (err) {
    const error = err.response ? err.response.data.message : err.message;
    dispatch({ type: GET_FAIR_DATA_SUCCESS, payload: error });
  }
};

export const setSearch = (search) => {
  const searchParams = new URLSearchParams(search);

  if (searchParams.has('page')) {
    searchParams.delete('page');
  }

  if (Array.from(searchParams.entries()).length === 0) {
    return {
      type: SET_SEARCH,
      payload: ''
    };
  }

  return {
    type: SET_SEARCH,
    payload: '?' + searchParams.toString()
  };
};


export const getExperimentIdsFields = ({ sendRequest, experimentId }) => async (dispatch) => {
  try {
    dispatch({ type: GET_EXPERIMENT_IDS_FIELDS_REQUEST });

    const { data } = await sendRequest('post', '/entrydata/get-ids-fields-by-experiment', {
      experiment_id: Number(experimentId)
    });

    const payload = {
      experimentId,
      items: data.ids_fields || []
    };

    dispatch({
      type: GET_EXPERIMENT_IDS_FIELDS_SUCCESS,
      payload
    });
  }
  catch (err) {
    const error = err.response ? err.response.data.message : err.message;
    dispatch({ type: GET_EXPERIMENT_IDS_FIELDS_ERROR, payload: error });
  }
};

export const clearEntryDataError = () => ({
  type: CLEAR_ENTRY_DATA_ERROR
});

export const clearExperimentInfoError = () => ({
  type: CLEAR_EXPERIMENT_INFO_ERROR
});