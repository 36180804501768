import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

const ValuesTable = ({ groupedEntryData }) => {
  const { t } = useTranslation();
  
  return (
    <div className="mt-2 variables-values-wrapper">
      {groupedEntryData.map(({ variableValue, time }) => (
        <Row key={time}>
          <Col>
            <span>{t('variables_container_value_label')}:{' '}
              <span>{variableValue}</span>
            </span>
          </Col>
          <Col>
            <span>{t('variables_container_time_label')}:{' '}
              <span>{time}</span>
            </span>
          </Col>
        </Row>
      ))}
    </div>
  );
};

ValuesTable.propTypes = {
  groupedEntryData: PropTypes.array
};

export default ValuesTable;
